import React from 'react';
import Header from '../components/HeaderComponent';
import TermsConditions from '../components/TermsConditionsComponent';
import Footer from '../components/FooterComponent';

import MainPageMetadata from '../metadata/MainPageMetadata';

function TermsConditionsPage(props) {
    return (
        <div>
            <MainPageMetadata pathname={ props.location.pathname } />
            <Header />
            <TermsConditions />
            <Footer />
        </div>
    );
}

export default TermsConditionsPage;
